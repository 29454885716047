import {normalizedType, Normalizer} from "./normalizer";
import {ShipmentCargaison} from "../../model/ShipmentCargaison";

const ShipmentCargaisonNormalizer = new class implements Normalizer<ShipmentCargaison>{
    normalize(data: normalizedType<ShipmentCargaison>): ShipmentCargaison {

        const shipmentCargo = new ShipmentCargaison(
            data.id,
            data.size,
            data.type,
            data.nbPackages,
            data.unit,
            data.weight,
            data.volume,
            data.goodsDescription,
            data.specificTemperature,
            data.hazard,
            data.tcNumber,
            data.lead
        );

        shipmentCargo.length = data.length;
        shipmentCargo.width = data.width;
        shipmentCargo.height = data.height;
        shipmentCargo.codeOnu = data.codeOnu;
        shipmentCargo.class = data.class;
        shipmentCargo.temperature = data.temperature;
        shipmentCargo.packingGroup = data.packingGroup;
        shipmentCargo.orderCode = data.orderCode;

        return shipmentCargo;
    }
}();

export { ShipmentCargaisonNormalizer };
