import {Alert, Col, Row} from "react-bootstrap";
import {formatDate} from "../../../../../utils";
import {renderListingProps} from "../../../../../components/ListingController";
import {Comment} from "../../../../../model/Comment";
import classNames from "classnames";
import {CSSProperties} from "react";

interface ChatProps<ResourceT extends Comment> extends renderListingProps<ResourceT> {}

export function Chat<ResourceT extends Comment> ({resources}: ChatProps<ResourceT>) {

    return (
        <>
            {
                resources.length === 0
                    ? (<Alert variant={"info"}>Aucun échange avec le client pour le moment.</Alert>)
                    : resources.map(({id, comment, createdAt, createdBy, createdByClient, attachedFileName}) => {
                        const date = formatDate(createdAt, 'fr-FR', {}, 'date');
                        const time = formatDate(createdAt, 'fr-FR', {}, 'time');

                        const blockStyle: CSSProperties = {}

                        blockStyle.borderRadius = '10px'

                        if (createdByClient) {
                            blockStyle.background = '#00ad78'
                        } else {
                            blockStyle.background = '#d68bbc'
                        }

                        return (
                            <Row className={classNames('m-3', createdByClient ? null : 'flex-row-reverse')} key={id}>
                                <Col lg={10} className={'text-white px-2 py-1'} style={blockStyle}>
                                    <p className={'mb-2 fw-bolder'}>
                                        {
                                            `${createdBy?.firstname} ${createdBy?.lastname} le ${date} à ${time} :`
                                        }
                                    </p>
                                    {
                                        attachedFileName
                                            ? (
                                                <p className="pb-2"><i>Fichier {attachedFileName} joint à ce chat</i></p>
                                            ) : null
                                    }
                                    <p className={'pe-2 white-space-pre-wrap'}>
                                        {comment}
                                    </p>
                                </Col>
                            </Row>
                        );
                    })
            }
        </>
    )
}