import {FileDisplay, FileManager} from "../FileManager";
import {Col, Row} from "react-bootstrap";
import Spinner from "../../../Spinner";
import * as React from "react";
import {useEffect, useState} from "react";
import useDatasource from "../../../../hooks/useDatasource";
import {ShipmentAttachedFile} from "../../../../model/ShipmentAttachedFile";
import {Shipment} from "../../../../model/Shipment";
import {ShipmentAttachedFileNormalizer} from "../../../../services/normalizer/ShipmentAttachedFileNormalizer";
import {Comment} from "../../../../model/Comment";
import {CommentNormalizer} from "../../../../services/normalizer/ShipmentCommentaireNormalizer";
import {FileResource} from "../../../../model/interface/FileResource";
import {CanceledError} from "axios";

interface FileTabProps {
    shipment: Shipment | null
}

interface CommentWithFile extends Comment {
    attachedFileName: string
}

const FileTab = ({shipment}: FileTabProps) => {

    const [files, setFiles] = useState<ShipmentAttachedFile[]>([]);
    const [commentsFile, setCommentsFile] = useState<FileResource[]>([]);

    const {index} = useDatasource<ShipmentAttachedFile>('shipment_attached_files', ShipmentAttachedFileNormalizer);
    const {index: commentIndex} = useDatasource<Comment>('shipment_comments', CommentNormalizer);

    const addFiles = (files: ShipmentAttachedFile[]) => {
        setFiles((prevFiles) => {
            console.log(files);
            return prevFiles.concat(files);
        });
    }

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            if (!shipment) {
                return;
            }
            const data = await index({shipment: shipment.id});

            setFiles(data);
        }

        fetchData().catch(console.error);

        (async () => {
            if (!shipment) {
                return;
            }

            try {
                const comments = (await commentIndex({shipment: shipment.id}, {id: "DESC"}, controller));
                setCommentsFile(
                    comments
                        .filter((el): el is CommentWithFile => el.attachedFileName !== null)
                        .map(
                            el => (
                                {
                                    id: el.id,
                                    mime: 'todo-mime',
                                    name: el.attachedFileName,
                                    url: `/api/shipment_comments/${el.id}/download?objectType=ShipmentCommentaire`
                                }
                            )
                        )
                );
            } catch (e) {
                if (e instanceof CanceledError) {
                    return;
                }
                throw e;
            }

        })();

        return () => {
            controller.abort();
        }
    }, [commentIndex, index, shipment]);

    if (!shipment) {
        return (
            <Spinner/>
        );
    }

    return (
        <>
            <Row>
                <Col md={3}>
                    <FileManager
                        files={files}
                        type={'shipmentDocument'}
                        shipment={shipment}
                        onUploadSuccess={addFiles}
                        confirmUpload={false}
                    />
                    {
                        commentsFile.map(
                            (el) => (<FileDisplay key={el.id} f={el}/>)
                        )
                    }
                </Col>
            </Row>
        </>
    );
};

export default FileTab