import {ShipmentCargoUnit} from "./ShipmentCargoUnit";
import {ShipmentCargoType} from "./ShipmentCargoType";
import {ShipmentCargoSize} from "./ShipmentCargoSize";

export class ShipmentCargaison {
    length: number | null = null;
    width: number | null = null;
    height: number | null = null;
    temperature: number | null = null;
    class: string | null = null;
    packingGroup: string | null = null;
    codeOnu: string | null = null;
    orderCode: string | null = null;

    constructor(
        readonly id: number,
        public size: ShipmentCargoSize | null,
        public type: ShipmentCargoType | null,
        public nbPackages: number | null,
        public unit: ShipmentCargoUnit | null,
        public weight: number | null,
        public volume: number | null,
        public goodsDescription: string | null,
        public specificTemperature: boolean | null,
        public hazard: boolean | null,
        public tcNumber: string | null,
        public lead: string | null,
    ) {
    }

    get dimensions(): string | null {
        return (this.length + ' (L) ').repeat(Number(!!this.length))
            + (this.width + ' (l) ').repeat(Number(!!this.width))
            + (this.height + ' (h)').repeat(Number(!!this.height));
    }

    get isSpecificTemperature(): string {
        switch (this.specificTemperature) {
            case true:
                return 'Oui';
            case false:
            default:
                return 'Non';
        }
    }

    get isHazard(): string {
        switch (this.hazard) {
            case true:
                return 'Oui';
            case false:
            default:
                return 'Non';
        }
    }

    get unitName(): string {
        return this.unit?.name ?? '';
    }

    get typeName(): string {
        return this.type?.name ?? '';
    }

    get sizeName(): string {
        return this.size?.name ?? '';
    }
}

export type ShipmentCargaisonType = ShipmentCargaison;