import {normalizedType, Normalizer, serializedType} from "./normalizer";
import {Comment} from "../../model/Comment";

const CommentNormalizer = new class implements Normalizer<Comment>{
    normalize(data: normalizedType<Comment>): Comment {
        const comment = new Comment(
            data.id,
            data.comment,
        );

        comment.createdBy = data.createdBy;
        comment.createdAt = new Date(data.createdAt);
        comment.shipment = data.shipment;
        comment.createdByClient = data.createdByClient;
        comment.attachedFile = data.attachedFile;
        comment.attachedFileName = data.attachedFileName;

        return comment;
    }

    serialize(data: Partial<Comment>, context: any): serializedType<Comment> {
        switch (context) {
            case'dossier':
                return {
                    comment: data.comment,
                    shipment: data.shipment,
                }
            default:
                return data;
        }
    }
}();

export { CommentNormalizer };
